import store from "@/store/index";
import { zipObject, map } from "lodash";
import { updateLayers } from "@/common/api.layers";

let deck = window.deck;

/**
 * INTERNAL STATE
 * @property {DeckGL object} deckgl - The deckgl instance
 * @property {Boolean} moved - Set to true on user interaction (click, drag)
 * @property {Boolean} multipleViz - Switch btw 1 or more devices
 * @property {Boolean} loaded - Set to true when deckgl is loaded
 */
let mapState = {
  deckgl: undefined,
  moved: false,
  multipleViz: true,
  loaded: false,
  animationStarted: false,
  pauseAnimation: true
};

/**
 * SETTINGS
 * @constant WAIT_AFTER_INTERACTION - Blocking time after any user interaction for camera updates [ms]
 * @constant INITIAL_VIEW_STATE - deckgl initial camera settings
 */
const WAIT_AFTER_INTERACTION = 10000;
const INITIAL_VIEW_STATE = {
  longitude: 9.597433,
  latitude: 45.646291,
  zoom: 15,
  pitch: 30
};

// DEMO MODE ====================================================

import { content } from "./data";

let lines = content.split("\n");
let fake_data = [];
let headers = lines[0].split(";");
for (let line of lines.slice(1)) {
  if (!line) {
    continue;
  }
  let values = line.split(";").map(n => {
    return parseFloat(n);
  });
  fake_data.push(zipObject(headers, values));
}

let inputData = fake_data.map((fd, i) => {
  return {
    id: i,
    timestamp: fd["Timestamp"],
    latitude: fd["GPS-Lat"],
    longitude: fd["GPS-Lon"],
    color: [0, 0, 255],
    man_down: fd["Man-down"],
    driving_style_fast: fd["DG-CntFast"] + Math.random() * 5,
    driving_style_moderate: fd["DG-CntModerate"] + Math.random() * 5,
    driving_style_normal: fd["DG-CntNormal"] + Math.random() * 5,
    pothole: fd["MS-CntHard"] + Math.random() * 5,
    device_id: "aa-bb-cc-dd-ee-ff",
    gps_accuracy: Math.random() * 5,
    gateway_accuracy: Math.random() * 20
  };
});

// load fake data and start cycling on them
let isDemoOnGoing = false;
let counter = 0;
let loadedData = inputData.slice(0, counter);

let intervalId = null;
function start() {
  // debouncer
  if (isDemoOnGoing) {
    return;
  } else {
    isDemoOnGoing = true;
    counter++;
    console.log("pt", counter);
    let newFakePoint = inputData[counter] ? inputData[counter] : null;
    if (newFakePoint) {
      store.dispatch("dashboard/addPoint", newFakePoint);
    } else {
      store.dispatch("dashboard/setData", []);
      counter = 0;
      let newFakePoint = inputData[0];
      store.dispatch("dashboard/addPoint", newFakePoint);
      counter++;
      console.log("pt", counter);
    }
  }

  intervalId = setInterval(() => {
    if (!mapState.loaded) {
      // console.log("not loaded, return");
      return;
    }
    counter++;
    console.log("pt", counter);
    // get the point using counter but not remove it
    let newFakePoint = inputData[counter] ? inputData[counter] : null;
    console.log(newFakePoint);
    if (newFakePoint) {
      store.dispatch("dashboard/addPoint", newFakePoint);
    } else {
      store.dispatch("dashboard/setData", []);
      counter = 0;
      let newFakePoint = inputData[0];
      store.dispatch("dashboard/addPoint", newFakePoint);
      counter++;
      console.log("pt", counter);
    }
  }, 3000);

  window.onunload = () => {
    clearInterval(intervalId);
  };

  return intervalId;
}

let id = null;
const startDemo = reset => {
  if (reset) {
    // reset points
    store.dispatch("dashboard/setData", []);
    counter = 0;
  }
  id = start();
};
const stopDemo = reset => {
  if (reset) {
    // reset points
    store.dispatch("dashboard/setData", []);
    counter = 0;
  }
  clearInterval(id);
  isDemoOnGoing = false;
};

window.startDemo = startDemo;
window.stopDemo = stopDemo;

export { startDemo, stopDemo };

// END DEMO MODE ===========================================================

function animate() {
  if (!mapState.pauseAnimation) {
    updateLayers(loadedData.slice(), mapState.deckgl, mapState.multipleViz);
  }
  requestAnimationFrame(animate);
}

/**
 * Mount deck gl map in a container
 * @param {String} containerId
 */
export function mountMap(dbData, containerId, cb) {
  if (mapState.loaded) {
    console.warn("already loaded");
    return;
  }

  loadedData = dbData ? dbData.slice() : [];

  mapState.deckgl = new deck.DeckGL({
    initialViewState: INITIAL_VIEW_STATE,
    controller: true,
    container: containerId,
    mapboxApiAccessToken:
      "pk.eyJ1IjoibWF0LWR2aXNpb25sYWIiLCJhIjoiY2toMXk4djFxMDBjaTMycndpZ2hmbTl6NiJ9.8dlUOu1SYRY9ceS6NMAxoQ",
    mapStyle: "mapbox://styles/mapbox/streets-v9", // see https://docs.mapbox.com/api/maps/#styles
    // mapStyle: "mapbox://styles/mat-dvisionlab/ckh4oluzh0rsx19lmim3s5dpr",
    layers: [],
    debug: true,
    // events
    onLoad() {
      // console.log("loaded");
      mapState.loaded = true;
      setCameraView(loadedData);

      setTimeout(() => {
        if (dbData) {
          updateLayers(dbData.slice(), mapState.deckgl, mapState.multipleViz);
        }
        mapState.animationStarted = true;
        mapState.pauseAnimation = false;
        animate();
        cb();
      }, 1000); // wait for tiles to load
    },
    onViewStateChange: () => {
      // const viewport = new deck.WebMercatorViewport(viewState);
      // const nw = viewport.unproject([0, 0]);
      // const se = viewport.unproject([viewport.width, viewport.height]);
      // do what you need to do
      // console.log(nw, se);
    },
    onClick: () => {
      mapState.moved = new Date();
    },
    onDrag: () => {
      mapState.moved = new Date();
    },
    getTooltip: getTooltip
  });
}

/**
 * Tooltip definition
 * @param {Object} - {the hover object}
 */
function getTooltip({ object }) {
  if (!object) {
    return null;
  }
  const lat = object.latitude;
  const lng = object.longitude;

  return `\
    ptId         : ${object.id}
    timestamp    : ${object.timestamp}
    deviceId     : ${object.device_id}
    latitude     : ${Number.isFinite(lat) ? lat.toFixed(6) : ""}
    longitude    : ${Number.isFinite(lng) ? lng.toFixed(6) : ""}
    normal       : ${object.driving_style_normal}
    moderate     : ${object.driving_style_moderate}
    fast         : ${object.driving_style_fast}
    hard         : ${object.pothole}
    gps accuracy : ${object.gps_accuracy}
    gateway acc. : ${object.gateway_accuracy}
    `;
}

/**
 * Moved = true until 10" from last interaction
 */
function checkMoved() {
  let diff = new Date() - mapState.moved;
  return diff > WAIT_AFTER_INTERACTION;
}

/**
 * Set camera to have all points in the view
 * @param {Array} points
 */
function setCameraView(points) {
  if (points.length < 2) {
    return;
  }

  let lons = map(points, "longitude");
  let lats = map(points, "latitude");

  let maxLat = Math.max(...lats);
  let maxLon = Math.max(...lons);
  let minLat = Math.min(...lats);
  let minLon = Math.min(...lons);

  let focusLat = minLat + (maxLat - minLat) / 2;
  let focusLon = minLon + (maxLon - minLon) / 2;

  let diag = Math.sqrt(
    Math.pow(maxLat - minLat, 2) + Math.pow(maxLon - minLon, 2)
  );

  let zoom = 15 - diag * 25; // tuning parameters

  mapState.deckgl.setProps({
    initialViewState: {
      longitude: focusLon,
      latitude: focusLat,
      zoom: mapState.multipleViz ? zoom : 13,
      pitch: 45,
      transitionDuration: 500,
      transitionInterpolator: new deck.FlyToInterpolator({ speed: 10 })
    }
  });
}

/**
 * Add a new point in single device visualization mode
 * @param {Object} p - The new point to insert
 */
export function addNewPointSingleViz(p) {
  console.log(p);
  const NEW_VIEW_STATE = {
    longitude: p.position[0],
    latitude: p.position[1],
    zoom: 17,
    pitch: 45,
    transitionDuration: 3000,
    // transitionInterpolator: new deck.LinearInterpolator(),
    transitionInterpolator: new deck.FlyToInterpolator({ speed: 2 }),
    // makes pts animation restart at each new point (NOTE: use slice() to create a new object)
    onTransitionStart: function() {
      updateLayers(loadedData.slice(), mapState.deckgl, false);
    },
    onTransitionEnd: function() {
      loadedData.push(p);
      updateLayers(loadedData.slice(), mapState.deckgl, false);
    }
  };
  if (checkMoved()) {
    // move camera on target
    mapState.deckgl.setProps({ initialViewState: NEW_VIEW_STATE });
  }
}

/**
 * Add a new point in multiplw device visualization mode
 * @param {Object} p - The new point to insert
 */
export function addNewPointMultipleViz(p) {
  // remove placeholder
  if (loadedData.length == 1 && loadedData[0].device_id == "placeholder") {
    loadedData = [];
  }

  if (!mapState.animationStarted) {
    animate();
    mapState.animationStarted = true;
  }
  // pause path animation (allow points transition)
  mapState.pauseAnimation = true;
  console.log(p);
  loadedData.push(p);
  updateLayers(loadedData.slice(), mapState.deckgl, true);
  // restore path animation
  setTimeout(() => {
    mapState.pauseAnimation = false;
  }, 1000); // this delay matches with transition duration
  if (checkMoved()) {
    // move camera on target
    setCameraView(loadedData);
  }
}

export function reloadData(data, cb) {
  if (!mapState.animationStarted) {
    animate();
    mapState.animationStarted = true;
  }
  mapState.pauseAnimation = true;

  if (data.length == 0) {
    // add placeholder to avoide gpu noise FIXME
    loadedData = [{ device_id: "placeholder" }];
  } else {
    loadedData = data.slice();
  }

  updateLayers(loadedData.slice(), mapState.deckgl, false);

  if (checkMoved()) {
    // move camera on target
    setCameraView(loadedData);
  }

  setTimeout(() => {
    mapState.pauseAnimation = false;
  }, 1000); // this delay matches with transition duration

  if (cb) cb();
}

// TODO ?
// - textlayer: for labels
// - hexalayer: create a grid of bins (use getElevationWeight for pts different weight)
