function initialState() {
  return {
    data: [],
    lastPoint: {},
    isLoading: true,
    init: false,
    online: 2, // start with database modality
    activeDevice: null
  };
}

const state = {
  ...initialState()
};

const mutations = {
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  setData(state, value) {
    state.data = value;
  },
  addPoint(state, value) {
    state.data.push(value);
    state.lastPoint = value;
  },
  setLoading(state, value) {
    state.isLoading = value;
  },
  setInit(state, value) {
    state.init = value;
  },
  setOnline(state, value) {
    state.online = value;
  },
  setActiveDevice(state, value) {
    state.activeDevice = value;
  }
};

const actions = {
  setData({ commit }, value) {
    commit("setData", value);
  },
  addPoint({ commit }, value) {
    commit("addPoint", value);
  },
  setLoading({ commit }, value) {
    commit("setLoading", value);
  },
  setInit({ commit }, value) {
    commit("setInit", value);
  },
  setOnline({ commit }, value) {
    commit("setOnline", value);
  },
  setActiveDevice({ commit }, value) {
    commit("setActiveDevice", value);
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
