<template>
  <v-navigation-drawer
    v-model="sidePanel"
    app
    right
    clipped
    floating
    hide-overlay
    fixed
    stateless
  >
    <!-- potholes -->
    <p class="text-center mt-5">
      <font-awesome-icon icon="chart-line" color="#e57373" size="3x" />
    </p>
    <v-divider></v-divider>

    <v-container>
      <v-card color="secondary" class="h100 w100">
        <v-card-title class="py-0">
          <v-row>
            <v-col cols="7">
              <v-icon color="red lighten-2" class="mr-12" size="50">
                mdi-road-variant
              </v-icon>
              <div
                class="subtitle-2 font-weight-black grey--text text--darken-2 text-uppercase"
              >
                Potholes
              </div>
            </v-col>
            <v-col cols="5">
              <div>
                <span
                  class="display-1 font-weight-black grey--text text--darken-2"
                  v-text="potholeSum || '—'"
                ></span>
              </div>
            </v-col>
          </v-row>
        </v-card-title>

        <v-sheet color="transparent">
          <v-sparkline
            :key="String(potholeSum)"
            :smooth="16"
            :gradient="['#f72047', '#ffd200', '#1feaea']"
            :line-width="3"
            :value="potholeData"
            auto-draw
            stroke-linecap="round"
          ></v-sparkline>
        </v-sheet>
      </v-card>
    </v-container>
    <v-divider></v-divider>

    <!-- fast events -->

    <v-container>
      <v-card color="secondary" class="h100 w100">
        <v-card-title class="py-0">
          <v-row>
            <v-col cols="7">
              <v-icon color="red lighten-2" class="mr-12" size="50">
                mdi-bike-fast
              </v-icon>
              <div
                class="subtitle-2 font-weight-black grey--text text--darken-2 text-uppercase"
              >
                Fast Events
              </div>
            </v-col>
            <v-col cols="5">
              <div>
                <span
                  class="display-1 font-weight-black grey--text text--darken-2"
                  v-text="fastSum || '—'"
                ></span>
              </div>
            </v-col>
          </v-row>
        </v-card-title>

        <v-sheet color="transparent">
          <v-sparkline
            :key="String(fastSum)"
            :smooth="16"
            :gradient="['#f72047', '#ffd200', '#1feaea']"
            :line-width="3"
            :value="fastData"
            auto-draw
            stroke-linecap="round"
          ></v-sparkline>
        </v-sheet>
      </v-card>
    </v-container>
    <v-divider></v-divider>
  </v-navigation-drawer>
</template>
<script>
import { mapState } from "vuex";

import { map } from "lodash";

export default {
  name: "SidePanel",
  data() {
    return {};
  },
  computed: {
    potholeSum() {
      return this.potholeData
        ? Math.floor(this.potholeData.reduce((a, b) => a + b, 0))
        : null;
    },
    fastSum() {
      return this.fastData
        ? Math.floor(this.fastData.reduce((a, b) => a + b, 0))
        : null;
    },
    sidePanel: {
      get() {
        return this.$store.state.sidePanel;
      },
      set(value) {
        this.$store.commit("setSidePanel", value);
      }
    },
    ...mapState({
      potholeData(state) {
        return map(state.dashboard.data, "pothole");
      },
      fastData(state) {
        return map(state.dashboard.data, "driving_style_fast");
      }
    })
  }
};
</script>

<style scoped></style>
