<template>
  <v-container fluid class="fill-height">
    <v-chip class="device-label" color="primary" label :ripple="false">
      <v-icon left>
        mdi-motorbike
      </v-icon>
      {{ device_id }}
    </v-chip>

    <v-btn class="mt45 primary" absolute fab top right @click="toggleMenu">
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <v-row class="h100">
      <v-col cols="12" sm="12" xs="12" class="py-0">
        <v-card color="#E6E4E0" class="h100 w100 px-2">
          <div id="map-container" class="h100 w100"></div>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import { mapState } from "vuex";

import { getTodayData } from "@/common/api.data";
import { mountMap, addNewPointMultipleViz, reloadData } from "@/common/api.map";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      dashboardData(state) {
        return state.dashboard.data;
      },
      lastPoint(state) {
        return state.dashboard.lastPoint;
      },
      isLoading(state) {
        return state.dashboard.isLoading;
      },
      init(state) {
        return state.dashboard.init;
      },
      device_id(state) {
        return state.dashboard.activeDevice && state.dashboard.activeDevice.tag
          ? "Device Id: " + state.dashboard.activeDevice.tag
          : "";
      }
    })
  },
  methods: {
    toggleMenu() {
      let menu = this.$store.state.sidePanel;
      this.$store.dispatch("setSidePanel", !menu);
    }
  },
  created() {
    this.$store.dispatch("setCurrentPage", "dashboard");
  },
  mounted() {
    getTodayData();
  },
  beforeDestroy() {
    this.$store.dispatch("dashboard/setLoading", true);
  },
  watch: {
    dashboardData(newValue) {
      console.log("dashboardData", newValue);

      let self = this;
      function removeLoader() {
        self.$store.dispatch("dashboard/setLoading", false);
      }

      if (!this.init && this.isLoading) {
        // first render
        mountMap(newValue, "map-container", removeLoader);
        this.$store.dispatch("dashboard/setInit", true);
      } else if (this.isLoading) {
        reloadData(newValue, removeLoader);
      } else {
        reloadData(newValue);
      }
    },
    lastPoint(newPoint) {
      let storedDeviceId = this.$store.state.dashboard.activeDevice.id;
      if (newPoint.device_id == storedDeviceId || storedDeviceId == "all") {
        addNewPointMultipleViz(newPoint);
      }
    }
  }
};
</script>

<style scoped>
.ptb0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.mt45 {
  margin-top: 45px;
}
.device-label {
  position: absolute;
  top: 25px;
  left: 25px;
  margin-bottom: 0px;
  z-index: 999;
  color: #e57373;
}
</style>
