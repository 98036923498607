<template>
  <div>
    <v-app-bar app clipped-right class="elevation-1" height="55">
      <ToolBar />
    </v-app-bar>
    <v-app>
      <v-main>
        <!-- <router-view name="content" /> -->
        <Dashboard name="content" />
      </v-main>
      <SidePanel />
    </v-app>
  </div>
</template>

<script>
import ToolBar from "@/components/ToolBar";
import SidePanel from "@/components/SidePanel";

// fonts
import "typeface-source-sans-pro";

// multilanguage
import i18n from "@/plugins/i18n";
import { without } from "lodash";
import Dashboard from "./views/Dashboard.vue";

export default {
  name: "App",
  data() {
    return {
      languages: ["en"],
      language: "en"
    };
  },
  components: { ToolBar, SidePanel, Dashboard },
  methods: {
    switchLocale() {
      let language = without(this.languages, this.language)[0];
      this.language = language;
      i18n.locale = language;
    }
  }
};
</script>

<style>
html {
  overflow: hidden;
}
body {
  font-family: "source-sans-pro" !important;
}
.h100 {
  height: 100%;
}
.w100 {
  width: 100%;
}
.color-clash {
  color: #007ec3 !important;
}
.center-text {
  text-align: center;
}
.log {
  text-align: center;
  margin-top: 1em;
  font-size: 1.5vmax;
}
.v-toolbar__content,
.v-toolbar__extension {
  padding: 0px !important;
}
.text-style {
  font-weight: 600;
  font-family: system-ui;
}
</style>
