export const content = `Timestamp;GPS-Lat;GPS-Lon;Man-down;DG-CntNormal;DG-CntModerate;DG-CntFast;MS-CntHard
1604048400000;45.649189;9.603744;0;10;5;0;0
1604048520000;45.648064;9.610351;0;11;6;0;0
1604048640000;45.650864;9.620895;0;12;7;0;0
1604048760000;45.650986;9.621498;0;13;8;0;0
1604048880000;45.653154;9.622913;0;14;9;0;0
1604049000000;45.653252;9.623235;1;15;10;0;0
1604049120000;45.653575;9.623288;0;14;10;2;1
1604049240000;45.669603;9.638818;0;13;9;2;1
1604049360000;45.669837;9.639333;0;12;8;2;1
1604049480000;45.674769;9.645217;0;11;7;2;1
1604049600000;45.675594;9.649454;1;10;6;2;1
1604049720000;45.676052;9.666047;0;9;5;2;1
1604049840000;45.675541;9.668752;0;1;11;4;2
1604049960000;45.676150;9.669774;0;2;12;4;2
1604050080000;45.677311;9.670566;0;3;13;4;2
1604050200000;45.680024;9.674993;1;4;14;4;2
1604050320000;45.685123;9.683699;0;5;15;4;2
1604050440000;45.693383;9.692366;0;6;16;4;2
1604050560000;45.702141;9.695865;0;6;16;2;1
1604050680000;45.704456;9.694678;0;5;15;2;1
1604050800000;45.706328;9.693906;1;4;14;2;1
1604050920000;45.708918;9.690297;0;3;13;2;1
1604051040000;45.711486;9.687948;0;2;12;2;1
1604051160000;45.713005;9.685309;0;1;11;2;1
1604051280000;45.712913;9.684668;0;16;10;0;0
1604051400000;45.712421;9.684662;1;17;9;0;0
1604051520000;45.710385;9.683242;0;18;8;0;0
1604051640000;45.710162;9.683058;0;19;7;0;0
1604051760000;45.709775;9.682465;0;20;6;0;0
1604051880000;45.709699;9.682381;0;21;5;0;0
1604052000000;45.708862;9.681669;0;22;4;0;0
`;
