import store from "@/store/index";
// external libraries
import request from "superagent";

import mockData from "./mockData";

const randomMockData = mockData.map(d => {
  d.driving_style_fast = Math.round(Math.random() * 10);
  d.driving_style_moderate = Math.round(Math.random() * 10);
  d.driving_style_normale = Math.round(Math.random() * 10);

  return d;
});

// ====================================
// Get data between now and now - h ===
// ====================================
export function getTodayData(hoursAgo = 0, cb) {
  console.log("getTodayData", hoursAgo);
  // request
  //   .get(`/api/filterdataset/?hours=${hoursAgo}`)
  //   .then(function(resp) {
  //     console.log(resp);
  //     store.dispatch("dashboard/setData", resp.body);
  //     if (cb) cb(resp.body);
  //   })
  //   .catch(error => {
  //     if (cb) cb(error);
  //   });
  setTimeout(() => {
    let data = randomMockData;
    if (hoursAgo > 0) {
      // extract from the array only the data between now and now - hoursAgo
      // for demo purposes, we use the first point as the reference
      const firstPointDate = new Date(
        randomMockData[randomMockData.length - 1].timestamp
      );
      const now = firstPointDate.getTime();
      const nowMinusHours = new Date(now - hoursAgo * 60 * 60 * 1000);
      data = randomMockData.filter(d => {
        return new Date(d.timestamp) > nowMinusHours;
      });
    }

    store.dispatch("dashboard/setData", data);
    if (cb) cb(data);
  }, 100);
}

// ====================================
// Send offline data to the server ====
// ====================================
export function sendData(fileObj, callback) {
  request
    .post("/api/upload/")
    .attach("events", fileObj)
    .then(function(resp) {
      console.log(resp);
      callback(resp);
    })
    .catch(error => {
      console.error(error);
    });
}
