import Vue from "vue";
import Vuex from "vuex";
// import createWebSocketPlugin from "./api.websockets.js";
import dashboard from "./modules/dashboard.js";

// modules

Vue.use(Vuex);

// const ws_url =
//   process.env.NODE_ENV == "development"
//     ? "ws://0.0.0.0:8000/ws/data/"
//     : "wss://80.211.113.165/ws/data/";

// var ws = new WebSocket(ws_url);

// const webSocketPlugin = createWebSocketPlugin(ws);

function initialState() {
  return {
    currentPage: "home",
    sidePanel: true,
    accessToken: null,
    refreshToken: null
  };
}

export default new Vuex.Store({
  state: {
    ...initialState()
  },
  mutations: {
    reset(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach(key => {
        if (key != "accessToken" && key != "refreshToken") {
          state[key] = s[key];
        }
      });
    },
    setUser(state, value) {
      state.user = value;
    },
    setAccessToken(state, value) {
      state.accessToken = value;
    },
    setRefreshToken(state, value) {
      state.refreshToken = value;
    },
    setCurrentPage(state, value) {
      state.currentPage = value;
    },
    setSidePanel(state, value) {
      state.sidePanel = value;
    }
  },
  actions: {
    clearState({ commit }) {
      commit("reset");
    },
    setUser({ commit }, value) {
      commit("setUser", value);
    },
    setAccessToken({ commit }, value) {
      commit("setAccessToken", value);
    },
    setRefreshToken({ commit }, value) {
      commit("setRefreshToken", value);
    },
    setCurrentPage({ commit }, value) {
      commit("setCurrentPage", value);
    },
    setSidePanel({ commit }, value) {
      commit("setSidePanel", value);
    }
  },
  getters: {},
  modules: {
    dashboard: dashboard
  }
  // plugins: [webSocketPlugin]
});
