import { groupBy, map } from "lodash";

let deck = window.deck;

const macAddr = {
  "fake-mac-address": 1,
  "aa-bb-cc-dd-ee-ff-gg-hh": 2,
  "17-17-99-98-39-76-37-13-29": 3,
  "17-17-99-98-39-76-37-12-76": 4,
  "17-17-99-98-39-76-37-14-18": 5,
  "17-17-99-98-39-76-37-14-90": 6,
  "17-17-99-98-39-76-37-15-65": 7
};

/**
 * @constant {Object} - The colormap for each layer type
 */
const colorMap = {
  scatter: [
    [255, 255, 255], // white
    [0, 0, 0], // black
    [255, 99, 82], // red
    [82, 255, 99], // green
    [82, 255, 252], // light blue
    [99, 82, 255], // blue
    [252, 82, 255], // fucsia
    [255, 252, 82] // yellow
  ],
  // traffic light palette (https://www.color-hex.com/color-palette/35021)
  hard: [
    [45, 201, 55], //0: green
    [45, 201, 55], //0: green
    [45, 201, 55], //0: green
    [45, 201, 55], //0: green
    [45, 201, 55], //0: green
    [231, 230, 22], //1: yellow
    [231, 230, 22], //1: yellow
    [231, 230, 22], //1: yellow
    [231, 230, 22], //1: yellow
    [231, 230, 22], //1: yellow
    [231, 230, 22], //1: yellow
    [204, 50, 50], //2: red
    [204, 50, 50], //2: red
    [204, 50, 50], //2: red
    [204, 50, 50], //2: red
    [204, 50, 50] //2: red
  ],
  fast: [
    [45, 201, 55], //0: green
    [45, 201, 55], //1: green
    [153, 193, 64], //2: light green
    [153, 193, 64], //2: light green
    [231, 230, 22], //3: yellow
    [231, 230, 22], //3: yellow
    [219, 123, 43], //4: orange
    [219, 123, 43], //4: orange
    [204, 50, 50], //5: red
    [204, 50, 50], //5: red
    [204, 50, 50], //5: red
    [204, 50, 50], //5: red
    [204, 50, 50], //5: red
    [204, 50, 50], //5: red
    [204, 50, 50], //5: red
    [204, 50, 50], //5: red
    [204, 50, 50], //5: red
    [204, 50, 50], //5: red
    [204, 50, 50], //5: red
    [204, 50, 50], //5: red
    [204, 50, 50], //5: red
    [204, 50, 50] //5: red
  ]
};

/**
 * Custom-made easing function
 * @param {*} t - instant btw [0,1]
 */
function customEasing(t) {
  const K = 2; // K controls the over-growth
  let s = 1 - K * Math.sin(-t * Math.PI) - Math.cos((t * Math.PI) / 2);
  return s;
}

/**
 * Update layers (create if none)
 * @param {Array} data - The data array
 * @param {DeckGL Object} deckgl - The deckgl instance
 * @param {Bool} multipleViz - The 1 / multiple viz mode
 */
export function updateLayers(data, deckgl, multipleViz) {
  // DEV
  multipleViz = false; // TODO fix dimensions

  const scatterLayer = new deck.ScatterplotLayer({
    id: "scatter-layer",
    data: data,
    visible: true,
    filled: true,
    radiusScale: 3,
    radiusMinPixels: 5,
    opacity: 0.2,
    parameters: {
      depthTest: false
    },
    getPosition: p => [p.longitude, p.latitude],
    getFillColor: p => {
      let n = macAddr[p.device_id] ? macAddr[p.device_id] : 5;
      return colorMap.scatter[n];
    },
    getRadius: p => (p.gps_accuracy < 40 ? 10 : 20),
    pickable: true,
    autoHighlight: true,
    onClick: info => console.log(info),
    transitions: {
      getRadius: {
        duration: 1000,
        enter: t => {
          return [t[0] * 0.1];
        },
        easing: customEasing
      }
    }
    // updateTriggers: {
    //   getRadius: [l]
    // }
  });

  const columnLayer1 = new deck.ColumnLayer({
    id: "column-fast",
    data: data,
    visible: true,
    diskResolution: 32,
    extruded: true,
    pickable: true,
    autoHighlight: true,
    radius: multipleViz ? 20 : 10,
    offset: [1, 0],
    elevationScale: multipleViz ? 100 : 50,
    opacity: 0.5,
    getPosition: p => [p.longitude, p.latitude],
    getFillColor: p =>
      p.man_down ? [0, 0, 0, 0] : colorMap.fast[p.driving_style_fast],
    getElevation: p => 0.5 + p.driving_style_fast,
    transitions: {
      getElevation: {
        duration: 1000,
        enter: t => {
          return [t[0] * 0.1];
        }
      }
    }
  });

  const columnLayer2 = new deck.ColumnLayer({
    id: "column-hard",
    data: data,
    visible: true,
    diskResolution: 6,
    extruded: true,
    pickable: true,
    autoHighlight: true,
    radius: multipleViz ? 20 : 10,
    offset: [-1, 0],
    elevationScale: multipleViz ? 100 : 50,
    opacity: 0.5,
    getPosition: d => [d.longitude, d.latitude],
    getFillColor: d => (d.man_down ? [0, 0, 0, 0] : colorMap.fast[d.pothole]),
    getElevation: d => 0.5 + d.pothole,
    transitions: {
      getElevation: {
        duration: 1000,
        enter: t => {
          return [t[0] * 0.1];
        }
      }
    }
  });

  const dashStart = (Date.now() / 100) % 1000;

  const pathLayer = new DashPathLayer({
    id: "path-layer",
    data: Object.values(groupBy(data, "device_id")),
    pickable: false,
    opacity: 0.5,
    widthScale: 0.5,
    widthMinPixels: 5,
    getColor: p => {
      let n = macAddr[p[0].device_id] ? macAddr[p[0].device_id] : 5;
      return colorMap.scatter[n];
    },
    getPath: d => map(d, e => [e.longitude, e.latitude]),
    getWidth: () => 10,
    getDashArray: [3, 2],
    dashStart,
    widthUnits: "pixels",
    extensions: [
      new deck.PathStyleExtension({ dash: true, highPrecisionDash: true })
    ]
  });

  const iconLayer = new deck.IconLayer({
    id: "icon-layer",
    data: data,
    pickable: true,
    sizeScale: 15,
    // iconAtlas and iconMapping should not be provided
    // getIcon return an object which contains url to fetch icon of each data point
    getPosition: d => [d.longitude, d.latitude],
    getIcon: () => ({
      url: "./map-marker-alert.png",
      width: 256,
      height: 256,
      anchorY: 256
    }),
    getColor: d => {
      let n = macAddr[d.device_id];
      return colorMap.scatter[n];
    },
    // icon size is based on data point's contributions, between 2 - 25
    getSize: d => {
      return d.man_down ? 10 : 0;
    }
  });

  // HEXAGON layer
  // const hexaLayer = new deck.HexagonLayer({
  //   id: "hexagon-layer",
  //   data: data,
  //   pickable: false,
  //   extruded: false,
  //   radius: 200,
  //   opacity: 0.1,
  //   elevationScale: 4,
  //   getPosition: d => [d.longitude, d.latitude]
  // });

  // HEATMAP layer
  // const heatLayer = new deck.HeatmapLayer({
  //   id: "heatmap-layer",
  //   data: data,
  //   getPosition: d => [d.longitude, d.latitude]
  //   // getWeight: d => d.WEIGHT
  // });

  deckgl.setProps({
    layers: [
      scatterLayer,
      columnLayer1,
      columnLayer2,
      pathLayer,
      iconLayer
      // hexaLayer,
      // heatLayer
    ]
  });
}

/**
 * Custom layer: path with dash line flowing forward
 * @class DashPathLayer
 */
class DashPathLayer extends deck.PathLayer {
  getShaders() {
    const shaders = super.getShaders();
    shaders.inject["vs:#decl"] += `\
    uniform float dashStart;`;
    shaders.inject["vs:#main-end"] += `\
    vDashOffset -= dashStart;`;
    shaders.inject["fs:#main-end"] = `\
    if (vPathPosition.y > (vPathLength - vPathLength/10.0)){
      gl_FragColor.a = 0.0;
    } else {
      gl_FragColor.a = gl_FragColor.a * vPathPosition.y/vPathLength;
    }
    `;
    return shaders;
  }

  draw(opts) {
    opts.uniforms.dashStart = this.props.dashStart || 0;
    super.draw(opts);
  }
}
