var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h100 w100"},[_c('v-toolbar',{attrs:{"flat":"","dark":"","dense":"","height":"55"}},[_c('v-toolbar-title',[_c('a',{attrs:{"href":"https://www.dvisionlab.com","target":"_blank"}},[_c('v-img',{staticClass:"toolbar-logo-brand",attrs:{"src":"dvision_logo_2019_symbol.png","width":"70","max-width":"70","contain":""}})],1)]),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-btn-toggle',{attrs:{"mandatory":"","color":"#e57373","dense":"","group":""},on:{"change":_vm.getTimedData},model:{value:(_vm.time_interval),callback:function ($$v) {_vm.time_interval=$$v},expression:"time_interval"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":0,"disabled":_vm.online < 2}},on),[_c('span',{staticClass:"text-style"},[_vm._v("D")])])]}}])},[_c('span',[_vm._v("Demo data")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":168,"disabled":_vm.online < 2}},on),[_c('span',{staticClass:"text-style"},[_vm._v("1w")])])]}}])},[_c('span',[_vm._v("Last week data")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":24,"disabled":_vm.online < 2}},on),[_c('span',{staticClass:"text-style"},[_vm._v("24h")])])]}}])},[_c('span',[_vm._v("Last Day Data")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":8,"disabled":_vm.online < 2}},on),[_c('span',{staticClass:"text-style"},[_vm._v("8h")])])]}}])},[_c('span',[_vm._v("Last 8 Hours Data")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":1,"disabled":_vm.online < 2}},on),[_c('span',{staticClass:"text-style"},[_vm._v("1h")])])]}}])},[_c('span',[_vm._v("Last Hour Data")])])],1),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-btn-toggle',{attrs:{"mandatory":"","color":"#e57373","dense":"","group":""},model:{value:(_vm.online),callback:function ($$v) {_vm.online=$$v},expression:"online"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","value":0}},on),[_c('font-awesome-icon',{attrs:{"icon":"signal"}})],1)]}}])},[_c('span',[_vm._v("On-line Modality (Run live demo)")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","value":1}},on),[_c('font-awesome-icon',{attrs:{"icon":"power-off"}})],1)]}}])},[_c('span',[_vm._v("Off-line Modality (Stop live demo) ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","value":2}},on),[_c('font-awesome-icon',{attrs:{"icon":"database"}})],1)]}}])},[_c('span',[_vm._v("Off-line Modality (Load data from DB) ")])])],1),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"ripple":"","icon":"","disabled":_vm.devices.length == 0}},Object.assign({}, tooltip, menu)),[_c('font-awesome-icon',{attrs:{"icon":"motorcycle","size":"lg"}})],1)]}}],null,true)},[_c('span',[_vm._v("Filter by device id")])])]}}])},[_c('v-list',_vm._l((_vm.devices),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.changeActiveDevice(item)}}},[_c('v-list-item-title',{class:_vm.isDeviceActive(item.tag)},[_vm._v(" "+_vm._s(item.tag)+" ")])],1)}),1)],1),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-file-input',_vm._g({staticClass:"menu",attrs:{"type":"file","accept":".bin","hide-input":""},on:{"change":_vm.onFileChange},model:{value:(_vm.dataFile),callback:function ($$v) {_vm.dataFile=$$v},expression:"dataFile"}},on))]}}])},[_c('span',[_vm._v("Upload off-line data")])]),_c('v-spacer'),_c('span',{staticClass:"title text-style"},[_vm._v(" Geolocalization Demo ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('span',{staticClass:"version text-style"},[_vm._v(" "+_vm._s(_vm.appVersion)+" ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-toolbar-title',[_c('a',{attrs:{"href":"https://www.221e.com","target":"_blank"}},[_c('v-img',{staticClass:"toolbar-logo-brand",attrs:{"src":"logo.png","width":"70","max-width":"70","contain":""}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }