import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "@/views/Dashboard.vue";

Vue.use(VueRouter);

window.popStateDetected = false;
window.addEventListener("popstate", () => {
  window.popStateDetected = true;
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  {
    path: "/*",
    name: "dashboard",
    components: {
      content: Dashboard
    },
    beforeEnter: (_to, _from, next) => {
      const navigated = window.popStateDetected;
      window.popStateDetected = false;
      next(!navigated);
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: __dirname,
  routes
});

export default router;
