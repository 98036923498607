<template>
  <div class="h100 w100">
    <v-toolbar flat dark dense height="55">
      <v-toolbar-title>
        <a href="https://www.dvisionlab.com" target="_blank">
          <v-img
            src="dvision_logo_2019_symbol.png"
            width="70"
            max-width="70"
            contain
            class="toolbar-logo-brand"
          >
          </v-img>
        </a>
      </v-toolbar-title>

      <v-divider class="mx-4" vertical></v-divider>

      <v-btn-toggle
        v-model="time_interval"
        mandatory
        color="#e57373"
        dense
        group
        @change="getTimedData"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :value="0" :disabled="online < 2">
              <span class="text-style">D</span>
            </v-btn>
          </template>
          <span>Demo data</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :value="168" :disabled="online < 2">
              <span class="text-style">1w</span>
            </v-btn>
          </template>
          <span>Last week data</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :value="24" :disabled="online < 2">
              <span class="text-style">24h</span>
            </v-btn>
          </template>
          <span>Last Day Data</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :value="8" :disabled="online < 2">
              <span class="text-style">8h</span>
            </v-btn>
          </template>
          <span>Last 8 Hours Data</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :value="1" :disabled="online < 2">
              <span class="text-style">1h</span>
            </v-btn>
          </template>
          <span>Last Hour Data</span>
        </v-tooltip>
      </v-btn-toggle>

      <v-divider class="mx-4" vertical></v-divider>

      <v-btn-toggle v-model="online" mandatory color="#e57373" dense group>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon :value="0">
              <font-awesome-icon icon="signal" />
            </v-btn>
          </template>
          <span>On-line Modality (Run live demo)</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon :value="1">
              <font-awesome-icon icon="power-off" />
            </v-btn>
          </template>
          <span>Off-line Modality (Stop live demo) </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon :value="2">
              <font-awesome-icon icon="database" />
            </v-btn>
          </template>
          <span>Off-line Modality (Load data from DB) </span>
        </v-tooltip>
      </v-btn-toggle>

      <v-divider class="mx-4" vertical></v-divider>

      <v-menu offset-y>
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                v-on="{ ...tooltip, ...menu }"
                ripple
                icon
                :disabled="devices.length == 0"
              >
                <font-awesome-icon icon="motorcycle" size="lg" />
              </v-btn>
            </template>
            <span>Filter by device id</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in devices"
            :key="index"
            @click="changeActiveDevice(item)"
          >
            <v-list-item-title :class="isDeviceActive(item.tag)">
              {{ item.tag }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-divider class="mx-4" vertical></v-divider>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-file-input
            v-model="dataFile"
            v-on="on"
            type="file"
            accept=".bin"
            hide-input
            class="menu"
            @change="onFileChange"
          />
        </template>
        <span>Upload off-line data</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <span class="title text-style"> Geolocalization Demo </span>

      <v-divider class="mx-4" vertical></v-divider>

      <span class="version text-style"> {{ appVersion }} </span>

      <v-divider class="mx-4" vertical></v-divider>

      <v-toolbar-title>
        <a href="https://www.221e.com" target="_blank">
          <v-img
            src="logo.png"
            width="70"
            max-width="70"
            contain
            class="toolbar-logo-brand"
          >
          </v-img>
        </a>
      </v-toolbar-title>
    </v-toolbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { version } from "@/../package.json";
import { groupBy, keys, map, filter } from "lodash";

import {
  getTodayData
  // sendData
} from "@/common/api.data";

import { reloadData } from "@/common/api.map";
import { startDemo, stopDemo } from "../common/api.map";

export default {
  name: "ToolBar",
  data() {
    return {
      time_interval: 0,
      dataFile: null,
      activeDevice: null
    };
  },
  components: {},
  computed: {
    ...mapState({
      currentPage(state) {
        return state.currentPage;
      },
      devices(state) {
        let devices = keys(
          groupBy(state.dashboard.data, function(item) {
            return item.device_id;
          })
        );
        let allObj = {
          index: 0,
          tag: "All Devices",
          id: "all"
        };
        if (!this.activeDevice && devices.length > 0) {
          this.activeDevice = devices.length > 1 ? allObj : devices[0];
          this.$store.dispatch("dashboard/setActiveDevice", this.activeDevice);
        }
        if (devices.length == 0) {
          this.activeDevice = allObj;
          this.$store.dispatch("dashboard/setActiveDevice", this.activeDevice);
        }
        if (devices.length > 1) {
          devices.unshift("all");
        }
        let devices_ = map(devices, (d, i) => {
          return { index: i, tag: d == "all" ? "All Devices" : d, id: d };
        });
        return devices_;
      }
    }),
    online: {
      get() {
        return this.$store.state.dashboard.online;
      },
      set(value) {
        this.$store.commit("dashboard/setOnline", value);
      }
    },
    appVersion: function() {
      return "v" + version;
    }
  },
  methods: {
    getTimedData: function(value) {
      this.$store.dispatch("dashboard/setLoading", true);
      getTodayData(value);
    },
    onFileChange: function(fileObj) {
      console.log("onFileChange", fileObj);
      console.warn("This feature has been disabled");
      // let self = this;
      // sendData(fileObj, function(resp) {
      //   if (resp.status == 200) {
      //     // off-line modality
      //     // turn off websocket and get data according to this.time_interval
      //     this.$store.dispatch("dashboard/setOnline", false);
      //     getTodayData(this.time_interval);
      //     self.dataFile = null;
      //   }
      // });
    },
    changeActiveDevice: function(device) {
      this.activeDevice = device;
      this.$store.dispatch("dashboard/setActiveDevice", device);
      // filter data
      let data = this.$store.state.dashboard.data;
      let filteredData =
        device.id == "all"
          ? data.slice()
          : filter(data, ["device_id", device.id]).slice();
      reloadData(filteredData);
    },
    isDeviceActive: function(device_id) {
      return this.activeDevice.tag == device_id ? "activeDevice" : "";
    }
  },
  watch: {
    online(newValue, oldValue) {
      switch (newValue) {
        case 2:
          stopDemo(true);
          getTodayData(this.time_interval);
          break;
        case 1:
          stopDemo(oldValue === 2);
          break;
        case 0:
          startDemo(oldValue === 2);
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style scoped>
.toolbar-logo {
  margin-right: 1em;
  margin-left: 1em;
}
.toolbar-logo-brand {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.version {
  margin-left: 10px;
  margin-right: 10px;
}
.menu {
  margin-left: 10px;
}
.title {
  margin-right: 10px;
}
.activeDevice {
  color: #e57373;
}
</style>
